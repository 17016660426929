import React, { useState, useEffect } from 'react';
import { Button, ButtonGroup, ButtonToolbar } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { useToasts } from 'react-toast-notifications';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';

import { DataTable } from '../../components';
import APIClient from '../../services/APIClient';
import Utils from '../Utils';
import UIUtils from '../UIUtils';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import { DataTableColumnProps, ExportConfigColumn } from '../../types/dataTable';

export default function VendedorList() {
  useDocumentTitle('Vendedores');
  const { addToast } = useToasts();

  const [isDataLoading, setIsDataLoading] = useState(true);
  const [vendedores, setVendedores] = useState([]);

  useEffect(() => {
    const loadVendedores = () => {
      APIClient.get('/agents')
        .then((res) => {
          setIsDataLoading(false);
          setVendedores(res.data.data);
        })
        .catch((err) => {
          console.error(err);
          addToast(`Ocurrió un error: "${err.message}"`, {
            appearance: 'error',
          });
          setIsDataLoading(false);
        });
    };

    loadVendedores();
  }, []);

  const columns: DataTableColumnProps[] = [
    {
      dataField: 'id',
      text: 'ID',
      sort: true,
      hidden: true,
    },
    {
      dataField: 'erpCode',
      text: 'Código',
      sort: true,
      classes: 'font-monospace',
    },
    {
      dataField: 'nombre',
      text: 'Nombre',
      sort: true,
    },
    {
      dataField: 'email',
      text: 'Email',
      sort: true,
    },
    {
      dataField: 'phoneNumber',
      text: 'Teléfono',
      sort: true,
    },
    {
      dataField: 'eliminadoFlag',
      text: 'Estado',
      sort: true,
      formatter: (cellContent) => {
        if (cellContent === null) {
          return '';
        }
        const newCellContent =
          typeof cellContent !== 'boolean' ? Utils.stringToBoolean(cellContent) : cellContent;
        const UISettings = {
          text: { true: 'Inactivo', false: 'Activo' },
          color: { true: 'danger', false: 'success' },
        };
        return UIUtils.getStatusBadge(newCellContent, UISettings);
      },
    },
    {
      dataField: 'actions',
      isDummyField: true,
      text: '',
      formatter: (cellContent, row) => (
        <ButtonToolbar>
          <ButtonGroup>
            <LinkContainer to={`/agents/${row.id}`}>
              <Button size="sm" variant="outline-primary" title="Editar">
                <FontAwesomeIcon icon={faEdit} fixedWidth size="xs" />
              </Button>
            </LinkContainer>
          </ButtonGroup>
        </ButtonToolbar>
      ),
    },
  ];

  /* EXPORT DATA TO EXCEL */
  const exportColumns: ExportConfigColumn[] = [
    {
      fieldName: 'id',
      title: 'ID',
    },
    {
      fieldName: 'erpCode',
      title: 'Código',
    },
    {
      fieldName: 'nombre',
      title: 'Nombre',
    },
    {
      fieldName: 'email',
      title: 'Email',
    },
    {
      fieldName: 'eliminadoFlag',
      title: 'Inactivo',
      parseBoolean: true,
    },
  ];

  return (
    <div>
      <h1 className="page-title">Vendedores</h1>
      <DataTable
        columns={columns}
        data={vendedores}
        isDataLoading={isDataLoading}
        keyField="id"
        exportConfig={{
          exportURL: '/agents/export.xlsx',
          columns: exportColumns,
        }}
      />
    </div>
  );
}
