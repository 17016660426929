import React from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonGroup, ButtonToolbar, Col, Row, Modal, Spinner } from 'react-bootstrap';
import { Type } from 'react-bootstrap-table2-editor';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { withToastManager } from 'react-toast-notifications';
import { Link } from 'react-router-dom';
import { DataTable, EntityEditForm, FiltersPanel } from '../../components';
import APIClient from '../../services/APIClient';
import Utils from '../Utils';
import UIUtils, { QueryParameters } from '../UIUtils';
import { ClientState } from './clientInterface';
import FormSelect from '../../components/componentsTs/FormSelect';
import FormInput from '../../components/componentsTs/FormInput';
import { WithRouterProps, withRouter } from '../withRouter';
import { Cliente } from '../Interfaces/interfaces';
import { withDocumentTitle } from '../withDocumentTitle';
import { DataTableColumnProps } from '../../types/dataTable';

class ClienteList extends React.Component<WithRouterProps, ClientState> {
  static propTypes = {
    toastManager: PropTypes.object.isRequired,
  };

  constructor(props: WithRouterProps) {
    super(props);

    const pageNumbUrl = Utils.sanitizeQuery(['page'], props.location.search).page;
    const PageNum = parseInt(pageNumbUrl);
    const pageNumber = Utils.isPositiveInteger(PageNum) ? PageNum : 1;
    this.state = {
      apiParams: '',
      clientes: [],
      isDataLoading: true,
      totalSize: 0,
      // FILTROS
      tipos: [],
      vendedores: [],
      estados: [],
      listasPrecio: [],
      puntosEntrega: [],
      selectedTipoIds: [],
      selectedVendedorIds: [],
      selectedEstadoIds: [],
      filterStringTipo: '',
      filterStringVendedor: '',
      filterStringEstado: '',
      showModal: false,
      modalWarning: false,
      rowEditModal: {},
      columnEditModal: {},
      pageNumber: pageNumber,
      contacts: [],
    };

    this.loadClientes = this.loadClientes.bind(this);
  }

  async componentDidMount() {
    await this.getFilterData();
    await this.loadClientes();
    // await this.loadPuntosDeEntrega();
  }

  onTableUpdate = async (queryParameters: QueryParameters) => {
    this.setState({ isDataLoading: true });
    const { toastManager } = this.props;
    const { freeText, pagination, sorting } = queryParameters;
    const { filterStringTipo, filterStringVendedor, filterStringEstado } = this.state;
    try {
      const { direction, field } = sorting;
      const filterQuery = `${filterStringTipo}&${filterStringVendedor}&${filterStringEstado}`;

      const apiParams = `freeText=${freeText && `%${freeText}%`}&sortField=${
        field || 'id'
      }&sortDir=${direction || 'asc'}&excludeAssocFields=imagenes`;
      const clientesRes = await APIClient.get(
        `/clientes?&filter[$estado.descripcion$][or]=${'Activo'}&filter[$estado.descripcion$][or]=${'Inactivo'}&limit=${
          pagination.limit
        }&offset=${pagination.offset}&${apiParams}&${filterQuery}`,
      );

      window.history.pushState({ page: pagination.page }, '', `?page=${pagination.page}`);
      this.setState({
        clientes: clientesRes.data.data,
        totalSize: clientesRes.data.meta.total,
        puntosEntrega: [],
        pageNumber: pagination.page,
        apiParams,
      });
      // await this.loadPuntosDeEntrega();
    } catch (error: any) {
      toastManager.add(`Ocurrió un error: "${error.message}"`, {
        appearance: 'error',
      });
    } finally {
      this.setState({ isDataLoading: false });
    }
  };

  //#region FILTROS

  getFilterData = async () => {
    const { toastManager } = this.props;
    try {
      // get tipos
      const tiposRes = await APIClient.get('/cliente-tipos');
      // get vendedores
      const vendedoresRes = await APIClient.get('/agents');
      // get estados sin prospect
      const estadosRes = await APIClient.get(
        `/cliente-estados?filter[id][or]=${'1'}&filter[id][or]=${'2'}`,
      );
      // get listas de precio
      const listasPrecioRes = await APIClient.get('/listas-precio');
      this.setState({
        tipos: tiposRes.data.data,
        vendedores: vendedoresRes.data.data,
        estados: estadosRes.data.data,
        listasPrecio: listasPrecioRes.data.data,
      });
    } catch (err) {
      console.error('Error al obtener la información de los filtros: ', err);
      toastManager.add(`No se pudo obtener la información de los filtros. ${err}`, {
        appearance: 'error',
      });
    }
  };

  createSelectAllButtons = (entityName: string) => {
    const label = `${entityName.substr(0, 1).toUpperCase()}${entityName.substr(1)} `;
    return (
      <p className="m-0">
        {label}(
        <button
          id={`select-all-${entityName}`}
          type="submit"
          className="link-button text-primary"
          onClick={() => this.handleSelectAll(entityName)}>
          Seleccionar todos
        </button>
        )
      </p>
    );
  };

  handleDataChange = (e: any) => {
    const { id, value, options, multiple } = e.target;
    let filterName = '';
    let fieldName = '';
    switch (id) {
      case 'selectedTipoIds':
        fieldName = 'tipoId';
        filterName = 'filterStringTipo';
        break;
      case 'selectedVendedorIds':
        fieldName = 'vendedorId';
        filterName = 'filterStringVendedor';
        break;
      case 'selectedEstadoIds':
        fieldName = 'estadoId';
        filterName = 'filterStringEstado';
        break;
      default:
        break;
    }
    if (multiple) {
      const values = [...options].filter((opt) => opt.selected).map((opt) => opt.value);
      this.setState((prevState: ClientState) => ({
        ...prevState,
        [id]: values,
        [filterName]: Utils.prepareQueryToFilter(fieldName, 'eq', values),
      }));
    } else {
      this.setState((prevState: ClientState) => ({
        ...prevState,
        [id]: value,
        [filterName]: Utils.prepareQueryToFilter(fieldName, 'eq', [value]),
      }));
    }
  };

  handleSelectAll = (entityName: string) => {
    this.setState((prevState) => {
      const { tipos, vendedores, estados } = prevState;
      let {
        selectedTipoIds,
        selectedVendedorIds,
        selectedEstadoIds,
        filterStringTipo,
        filterStringVendedor,
        filterStringEstado,
      } = prevState;
      // map ids from entities into "selected" variables
      switch (entityName) {
        case 'tipos':
          selectedTipoIds = tipos.map((cla) => cla.id);
          filterStringTipo = Utils.prepareQueryToFilter('tipoId', 'or', selectedTipoIds);
          break;
        case 'vendedores':
          selectedVendedorIds = vendedores.map((cli) => cli.id);
          filterStringVendedor = Utils.prepareQueryToFilter(
            'vendedorId',
            'or',
            selectedVendedorIds,
          );
          break;
        case 'estados':
          selectedEstadoIds = estados.map((col) => col.id);
          filterStringEstado = Utils.prepareQueryToFilter('estadoId', 'or', selectedEstadoIds);
          break;
        default:
          break;
      }

      return {
        ...prevState,
        selectedTipoIds,
        selectedVendedorIds,
        selectedEstadoIds,
        filterStringTipo,
        filterStringVendedor,
        filterStringEstado,
      };
    });
  };

  handleFiltersClearClick = () => {
    this.setState({
      selectedTipoIds: [],
      selectedVendedorIds: [],
      selectedEstadoIds: [],
    });
  };

  //#endregion FIN_FILTROS

  //#region EDIT CELLS
  updateRowField = async (row: any, column: any, newValue: any) => {
    const { toastManager } = this.props;
    const { clientes, rowEditModal } = this.state;
    row.id = rowEditModal.id || row.id;
    const fieldToUpdate = rowEditModal.id ? row : { id: row.id, [column.dataField]: newValue };
    try {
      // call api UPDATE
      await APIClient.patch(`/clientes/${row.id}`, fieldToUpdate);
      toastManager.add('Cambio guardado', {
        appearance: 'success',
      });
      // Update the component's state with the changes
      const clienteToUpdateState = await APIClient.get(`/clientes/${row.id}`);
      const arrayToUpdateState = clientes.map((cliente) => {
        if (cliente.id === row.id) {
          return clienteToUpdateState.data.data;
        }
        return cliente;
      });

      this.setState((prevState) => ({
        ...prevState,
        clientes: arrayToUpdateState,
        rowEditModal: {},
        columnEditModal: {},
        showModal: false,
      }));
    } catch (err) {
      console.error('Error al actualizar el campo: ', err);
      toastManager.add(`No se pudo guardar la información. Inténtelo nuevamente. ${err}`, {
        appearance: 'error',
      });
    }
  };

  editModal = () => {
    this.setState({ showModal: true });
  };
  onRetrieveEntity = () => {};

  handleClose = (modal: string) => {
    this.setState({
      ...this.state,
      [modal]: false,
    });
  };
  //#endregion FIN EDIT_CELLS

  // Comment this entire logic because it makes the page load too slow
  // async loadPuntosDeEntrega() {
  //   const { clientes } = this.state;
  //   let puntosEntregaRes,
  //     puntosEntrega: any[] = [];
  //   for (let i = 0; i < clientes.length; i += 1) {
  //     puntosEntregaRes = await APIClient.get(`/clientes/${clientes[i].id}/puntos-entrega`);
  //     puntosEntrega.push({ clienteId: clientes[i].id, pDEs: puntosEntregaRes.data.data });
  //   }
  //   this.setState({
  //     puntosEntrega: puntosEntrega,
  //     modalWarning: false,
  //   });
  // }

  async loadClientes() {
    const { toastManager } = this.props;
    const { pageNumber } = this.state;

    let offset;

    if (pageNumber !== undefined) {
      offset = (pageNumber - 1) * 10;
    }

    try {
      const clientesRes = await APIClient.get(
        `/clientes?filter[$estado.descripcion$][or]=${'Activo'}&filter[$estado.descripcion$][or]=${'Inactivo'}&limit=10&offset=${offset}&sortField=id&sortDir=asc`,
      );

      const apiParams = 'sortField=id&sortDir=asc&excludeAssocFields=imagenes';
      this.setState({
        clientes: clientesRes.data.data,
        totalSize: clientesRes.data.meta.total,
        apiParams,
        isDataLoading: false,
      });
    } catch (err: any) {
      toastManager.add(`Ocurrió un error: "${err.message}"`, {
        appearance: 'error',
      });
    } finally {
      this.setState({ isDataLoading: false });
    }
  }

  render() {
    const {
      clientes,
      tipos,
      vendedores,
      estados,
      listasPrecio,
      isDataLoading,
      totalSize,
      selectedTipoIds,
      selectedVendedorIds,
      selectedEstadoIds,
      showModal,
      modalWarning,
      rowEditModal,
      columnEditModal,
      pageNumber,
      apiParams,
    } = this.state;

    const events = {
      onMouseEnter: (e: any) => {
        e.target.style.cursor = 'pointer';
      },
    };
    const showEditModal = {
      onDoubleClick: (e: any, column: any, columnIndex: any, row: any, rowIndex: any) => {
        this.setState((prevState) => ({
          ...prevState,
          rowEditModal: row,
          columnEditModal: column,
        }));
        this.editModal();
      },
      onMouseEnter: UIUtils.bgBlueOnMouseEnter.onMouseEnter,
      onMouseLeave: UIUtils.bgBlueOnMouseEnter.onMouseLeave,
    };

    const columns: DataTableColumnProps[] = [
      {
        dataField: 'id',
        text: 'id',
        hidden: true,
      },
      {
        dataField: 'erpCode',
        text: 'Código',
        sort: true,
        editable: () => false,
        classes: 'font-monospace',
      },
      {
        dataField: 'razonSocial',
        text: 'Razón Social',
        sort: true,
        formatter: (cellContent: any, row: any) => (
          <div>
            {row.razonSocial}
            <br />
            <small title="Nombre de Fantasía">{row.nombreFantasia}</small>
          </div>
        ),
        events: UIUtils.bgBlueOnMouseEnter,
      },
      {
        dataField: 'documentoNumero',
        text: 'CUIT',
        events: events,
        sort: true,
      },
      {
        dataField: 'estadoId',
        text: 'Estado',
        sort: true,
        events: UIUtils.bgBlueOnMouseEnter,
        formatter: (cellContent: any, row: any) => {
          if (cellContent === null) {
            return '';
          }
          cellContent = typeof cellContent === 'string' ? parseInt(cellContent, 10) : cellContent;
          const estadoFound = estados.find((estado) => cellContent === estado.id);
          const estado = estadoFound ? estadoFound.descripcion : row.estado.descripcion;
          return UIUtils.getClienteEstadoBadge(estado);
        },
      },
      {
        dataField: 'tipoId',
        text: 'Tipo',
        sort: true,
        events: events,
        csvFormatter: (cellContent: any) => {
          if (cellContent === null) {
            return '';
          }
        },
        formatter: (cellContent: any, row: any) => {
          if (cellContent === null) {
            return '';
          }
          cellContent = typeof cellContent === 'string' ? parseInt(cellContent, 10) : cellContent;
          const tipoFound = tipos.find((tipo) => cellContent === tipo.id);
          const tipo = tipoFound ? tipoFound.descripcion : row.tipo.descripcion;
          return tipo;
        },
      },
      {
        dataField: 'vendedorId',
        text: 'Vendedor',
        sort: true,
        events: events,
        hidden: true,
        csvFormatter: (cellContent: any) => {
          if (cellContent === null) {
            return '';
          }
        },
        formatter: (cellContent: any, row: any) => {
          if (cellContent === null) {
            return '';
          }
          cellContent = typeof cellContent === 'string' ? parseInt(cellContent, 10) : cellContent;
          const vendedorFound = vendedores.find((vendedor) => cellContent === vendedor.id);
          const vendedor = vendedorFound ? vendedorFound.nombre : row.vendedor.nombre;
          return vendedor;
        },
      },
      {
        dataField: 'listaPrecioId',
        text: 'Lista de precio',
        sort: true,
        events: events,
        csvFormatter: (cellContent: any) => {
          if (cellContent === null) {
            return '';
          }
        },
        formatter: (cellContent: any, row: Cliente) => {
          if (cellContent === null) {
            return '';
          }
          cellContent = typeof cellContent === 'string' ? parseInt(cellContent, 10) : cellContent;
          const listFound = listasPrecio.find((lista) => cellContent === lista.id);
          const listaPrecio = listFound
            ? listFound.descripcion
            : (row.ListaPrecio?.descripcion ?? '');
          return listaPrecio;
        },
      },

      {
        dataField: 'diasEntrega',
        text: 'Dias Entrega',
        hidden: true,
        csvFormatter: (cellContent: any) => {
          if (!cellContent) {
            return '';
          }
          return cellContent;
        },
      },
      {
        dataField: 'zonaId',
        text: 'Zona',
        sort: true,
        events: events,
        csvFormatter: (cellContent: any) => {
          if (cellContent === null) {
            return '';
          }
        },
        formatter: (cellContent: any, row: any) => {
          if (cellContent === null) {
            return '';
          }
          return row.zona?.descripcion;
        },
      },
      // {
      //   dataField: 'puntoEntregaId',
      //   text: 'Punto de entrega',
      //   sort: true,
      //   events: {
      //     onMouseEnter: (e: any, column: any, columnIndex: any, row: any, rowIndex: any) => {
      //       e.target.style.cursor = `pointer`;
      //     },
      //   },
      //   csvFormatter: (cellContent: any) => {
      //     if (!cellContent) {
      //       return '';
      //     }
      //   },
      //   formatter: (cellContent: any, row: any) => {
      //     if (!cellContent) {
      //       return '';
      //     }
      //     const rowPDE = puntosEntrega.find((pDE) => pDE.clienteId === row.id);
      //     if (!rowPDE) {
      //       return <FontAwesomeIcon icon={faCircleNotch} fixedWidth spin className="me-1" />;
      //     }
      //     cellContent = typeof cellContent === 'string' ? parseInt(cellContent, 10) : cellContent;
      //     const rowPDEFound = rowPDE.pDEs.find((pDE: any) => cellContent === pDE.id);
      //     return rowPDEFound ? (
      //       <div>
      //         <small>{generateDeliveryString(rowPDEFound)}</small>
      //       </div>
      //     ) : (
      //       <div>
      //         <small>{generateDeliveryString(row.puntoEntrega)}</small>
      //       </div>
      //     );
      //   },
      //   editor: {
      //     type: Type.SELECT,
      //     getOptions: (row: any) => {
      //       const rowPDE = puntosEntrega.find((pDE) => pDE.clienteId === row.id);
      //       if (puntosEntrega.length === 0 || rowPDE === undefined) {
      //         return this.setState({ modalWarning: true });
      //       }
      //       return rowPDE.pDEs.map((pDE: any) => {
      //         return { value: pDE.id, label: pDE.descripcion };
      //       });
      //     },
      //   },
      // },
      {
        dataField: 'eliminadoFlag',
        text: 'Eliminado Flag',
        hidden: true,
        csvFormatter: (cellContent: any) => {
          if (!cellContent) {
            return '';
          }
          return cellContent;
        },
      },
      {
        dataField: 'actions',
        isDummyField: true,
        text: '',
        csvExport: false,
        formatter: (cellContent: any, row: any) => (
          <ButtonToolbar>
            <ButtonGroup>
              {/* <LinkContainer to={`/clientes/${row.id}?page=${pageNumber}`}>
                <Button size="sm" variant="outline-primary" title="Editar">
                  <FontAwesomeIcon icon={faEdit} fixedWidth size="xs" />
                </Button>
              </LinkContainer> */}
              <Link
                to={{
                  pathname: `/clientes/${row.id}`,
                  search: `?page=${pageNumber}`,
                }}>
                <Button size="sm" variant="outline-primary" title="Editar">
                  <FontAwesomeIcon icon={faEdit} fixedWidth size="xs" />
                </Button>
              </Link>
              {/* <LinkContainer to={`/interacciones?cliente=${row.id}`}>
                <Button size="sm" variant="outline-primary" title="Interacción">
                  <FontAwesomeIcon icon={faFileAlt} fixedWidth size="xs" />
                </Button>
              </LinkContainer>
              <LinkContainer to={`/reporte-clientes/cliente/${row.id}?reporte=pedidos`}>
                <Button size="sm" variant="outline-primary" title="Historial de pedidos">
                  <FontAwesomeIcon icon={faDolly} fixedWidth size="xs" />
                </Button>
              </LinkContainer>
              <LinkContainer to={`/reporte-clientes/cliente/${row.id}?reporte=precios`}>
                <Button size="sm" variant="outline-primary" title="Precios y modificadores">
                  <FontAwesomeIcon icon={faDollarSign} fixedWidth size="xs" />
                </Button>
              </LinkContainer> */}
            </ButtonGroup>
          </ButtonToolbar>
        ),
      },
    ];

    return (
      <div>
        {/* WARNING MODAL */}
        <Modal size="lg" show={modalWarning} onHide={() => this.handleClose('modalWarning')}>
          <Modal.Header closeButton>
            <h4 className="ms-3">Puntos de entrega cargándose</h4>
            <Spinner animation="grow" />
          </Modal.Header>
          <Modal.Body></Modal.Body>
        </Modal>

        {/* EDIT MODAL */}
        <Modal size="lg" show={showModal} onHide={() => this.handleClose('showModal')}>
          <Modal.Header closeButton>
            <h4>
              {rowEditModal.razonSocial} - {columnEditModal.text}{' '}
            </h4>
          </Modal.Header>
          <Modal.Body>
            <EntityEditForm
              // onLoadForm={this.onLoadForm}
              onRetrieveEntity={this.onRetrieveEntity}
              onSaveEntity={this.updateRowField}>
              {columnEditModal.text === 'Contacto comercial' ? (
                <React.Fragment>
                  <Row>
                    <Col md={6}>
                      <FormInput
                        id="contactoComercial"
                        label="Nombre"
                        type="text"
                        defaultValue={rowEditModal.contactoComercial}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <FormInput
                        id="emailComercial"
                        label="Email"
                        type="email"
                        defaultValue={rowEditModal.emailComercial}
                      />
                    </Col>
                    <Col md={6}>
                      <FormInput
                        id="telefonoComercial"
                        label="Teléfono"
                        type="text"
                        defaultValue={rowEditModal.telefonoComercial}
                      />
                    </Col>
                  </Row>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <Row>
                    <Col md={6}>
                      <FormInput
                        id="contactoCobranzas"
                        label="Nombre"
                        type="text"
                        defaultValue={rowEditModal.contactoCobranzas}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <FormInput
                        id="emailCobranzas"
                        label="Email"
                        type="email"
                        defaultValue={rowEditModal.emailCobranzas}
                      />
                    </Col>
                    <Col md={6}>
                      <FormInput
                        id="telefonoCobranzas"
                        label="Teléfono"
                        type="text"
                        defaultValue={rowEditModal.telefonoCobranzas}
                      />
                    </Col>
                  </Row>
                </React.Fragment>
              )}
            </EntityEditForm>
          </Modal.Body>
          <Modal.Footer>
            {/* <Button variant="primary" onClick={this.handleClose}>
          Save Changes
        </Button> */}
          </Modal.Footer>
        </Modal>
        {/* FIN EDIT MODAL */}

        <h1 className="page-title">Clientes</h1>
        <FiltersPanel
          isDataLoading={isDataLoading}
          onFilterClick={() =>
            this.onTableUpdate({
              freeText: '',
              pagination: { limit: 10, offset: 0 },
              sorting: { field: 'id', direction: 'ASC' },
            })
          }
          onClearClick={this.handleFiltersClearClick}>
          <Row>
            <Col md={6}>
              <FormSelect
                id="selectedTipoIds"
                label={this.createSelectAllButtons('tipos')}
                value={selectedTipoIds}
                onChange={this.handleDataChange}
                choices={tipos}
                choiceIdField="id"
                choiceLabelField="descripcion"
                multiple
              />
            </Col>
            <Col md={6}>
              <FormSelect
                id="selectedVendedorIds"
                label={this.createSelectAllButtons('ejecutivos comerciales')}
                value={selectedVendedorIds}
                onChange={this.handleDataChange}
                choices={vendedores}
                choiceIdField="id"
                choiceLabelField="nombre"
                multiple
              />
            </Col>
            <Col md={6}>
              <FormSelect
                id="selectedEstadoIds"
                label={this.createSelectAllButtons('estados')}
                value={selectedEstadoIds}
                onChange={this.handleDataChange}
                choices={estados}
                choiceIdField="id"
                choiceLabelField="descripcion"
                multiple
              />
            </Col>
          </Row>
        </FiltersPanel>
        <DataTable
          remote={{
            filter: true,
            pagination: true,
            sort: true,
          }}
          totalSize={totalSize}
          columns={columns}
          data={clientes}
          onTableUpdate={this.onTableUpdate}
          isDataLoading={isDataLoading}
          keyField="id"
          // addButton="/clientes/nuevo"
          exportConfig={{
            exportURL: `/clientes/export.xlsx?${apiParams}`,
          }}
          defaultSorted={[{ dataField: 'codigo', order: 'desc' }]}
          updateRowField={this.updateRowField}
          pageNumber={pageNumber}
        />
      </div>
    );
  }
}

export default withToastManager(withRouter(withDocumentTitle(ClienteList, 'Clientes')));
