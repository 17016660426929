import React, { useEffect, useMemo, useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import { DataTable } from '../../components';
import APIClient from '../../services/APIClient';
import Utils from '../Utils';
import UIUtils from '../UIUtils';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import { DataTableColumnProps } from '../../types/dataTable';

export default function MarcaList() {
  useDocumentTitle('Marcas');
  const { addToast } = useToasts();

  const [entities, setEntities] = useState([]);
  const [isDataLoading, setIsDataLoading] = useState(true);

  useEffect(() => {
    loadMarcas();
  }, []);

  const loadMarcas = async () => {
    try {
      const res = await APIClient.get('/marcas?sortField=codigo&sortDir=asc');
      setEntities(res.data.data);
      setIsDataLoading(false);
    } catch (err) {
      console.error('Error loading brands', err);
      addToast(`Ocurrió un error: "${(err as Error).message}"`, {
        appearance: 'error',
      });
      setIsDataLoading(false);
    }
  };

  const columns: DataTableColumnProps[] = useMemo(
    () => [
      {
        dataField: 'erpCode',
        text: 'Código',
        sort: true,
        classes: 'font-monospace',
      },
      {
        classes: 'w-75',
        dataField: 'descripcion',
        text: 'Descripción',
        sort: true,
      },
      {
        classes: 'text-center',
        dataField: 'eliminadoFlag',
        text: 'Estado',
        headerClasses: 'text-center',
        sort: true,
        formatter: (cellContent, row) => {
          if (cellContent === null) {
            return '';
          }
          cellContent =
            typeof cellContent !== 'boolean' ? Utils.stringToBoolean(cellContent) : cellContent;
          const UISettings = {
            text: { true: 'Inactivo', false: 'Activo' },
            color: { true: 'danger', false: 'success' },
          };
          return UIUtils.getStatusBadge(cellContent, UISettings);
        },
      },
      // { TEMPORARY READ-ONLY
      //  dataField: 'actions',
      //  isDummyField: true,
      //  text: '',
      //  formatter: (cellContent, row) => (
      //    <ButtonToolbar>
      //      <ButtonGroup>
      //        <LinkContainer to={`/marcas/${row.codigo}`}>
      //          <Button size="sm" variant="outline-primary" title="Editar">
      //            <FontAwesomeIcon icon={faEdit} fixedWidth size="xs" />
      //          </Button>
      //        </LinkContainer>
      //      </ButtonGroup>
      //    </ButtonToolbar>
      //  ),
      // },
    ],
    [],
  );

  /* EXPORT DATA TO EXCEL */
  const exportColums = useMemo(
    () => [
      {
        fieldName: 'erpCode',
        title: 'Código',
      },
      {
        fieldName: 'descripcion',
        title: 'Descripción',
      },
      {
        fieldName: 'eliminadoFlag',
        title: 'Inactivo',
        parseBoolean: true,
      },
    ],
    [],
  );

  return (
    <div>
      <h1 className="page-title">Marcas</h1>

      <DataTable
        columns={columns}
        data={entities}
        isDataLoading={isDataLoading}
        keyField="codigo"
        // addButton="/marcas/nuevo"
        defaultSorted={[{ dataField: 'codigo', order: 'asc' }]}
        exportConfig={{
          exportURL: '/marcas/export.xlsx',
          columns: exportColums,
        }}
      />
    </div>
  );
}
