import React, { useEffect, useState } from 'react';
import { withToastManager } from 'react-toast-notifications';
import { Button, ButtonGroup, ButtonToolbar, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTimes } from '@fortawesome/free-solid-svg-icons';
import { LinkContainer } from 'react-router-bootstrap';
import { DataTable } from '../../components';
import APIClient from '../../services/APIClient';
import { WithRouterProps } from '../withRouter';
import UIUtils, { SettingsStatusBadge } from '../UIUtils';
import Utils from '../Utils';
import { Unit } from '../../types/model';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import { DataTableColumnProps } from '../../types/dataTable';

function UnitList({ toastManager }: WithRouterProps) {
  useDocumentTitle('Unidades');

  const [isDataLoading, setIsDataLoading] = useState(true);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState<Unit>();
  const [units, setUnits] = useState<Unit[]>([]);

  async function loadUnits(): Promise<void> {
    try {
      setIsDataLoading(true);
      const response = await APIClient.get('/units');
      setUnits(response.data.data);
    } catch (error) {
      console.error('Error in load Units', error);
      toastManager.add(`Ocurrió un error: "${error}"`, {
        appearance: 'error',
      });
    } finally {
      setIsDataLoading(false);
    }
  }

  useEffect(() => {
    loadUnits();
  }, []);

  function showModal(row: Unit): void {
    setSelectedRow(row);
    setShowDeleteModal(true);
  }

  async function handleDeleteConfirmClick(): Promise<void> {
    try {
      if (selectedRow) {
        const updatedUnits = units.filter((unit) => unit.id !== selectedRow.id);
        await APIClient.delete(`/units/${selectedRow.id}`);
        setUnits(updatedUnits);

        toastManager.add('Unidad eliminada con éxito', {
          appearance: 'success',
          autoDismiss: true,
        });
      } else {
        toastManager.add('Por favor seleccione una unidad para eliminar', {
          appearance: 'error',
          autoDismiss: true,
        });
      }
    } catch (error) {
      console.error('Error on delete Unit', error);
      toastManager.add(`Ocurrió un error: "${error}"`, {
        appearance: 'error',
      });
    } finally {
      setSelectedRow(undefined);
      setShowDeleteModal(false);
    }
  }

  function renderDeleteModal(): React.ReactNode {
    return (
      <Modal size="lg" show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Eliminar Unidad</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>¿Está seguro de que desea eliminar esta unidad?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleDeleteConfirmClick}>
            Confirmar
          </Button>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
            Cancelar
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  const columns: DataTableColumnProps[] = [
    {
      dataField: 'erpCode',
      text: 'Código',
      sort: true,
      classes: 'font-monospace',
    },
    {
      dataField: 'name',
      text: 'Nombre',
      sort: true,
    },
    {
      dataField: 'erpCode',
      text: 'Código interno',
      sort: true,
    },
    {
      dataField: 'enabled',
      text: 'Estado',
      events: UIUtils.bgBlueOnMouseEnter,
      formatter: (cellContent: any, row: any) => {
        if (cellContent === null) {
          return '';
        }
        cellContent =
          typeof cellContent !== 'boolean' ? Utils.stringToBoolean(cellContent) : cellContent;
        const settings: SettingsStatusBadge = {
          text: { true: 'Activo', false: 'Inactivo' },
          color: { true: 'success', false: 'danger' },
        };
        return UIUtils.getStatusBadge(cellContent, settings);
      },
    },
    {
      dataField: 'actions',
      isDummyField: true,
      text: '',
      formatter: (cellContent, row) => (
        <ButtonToolbar>
          <ButtonGroup>
            <LinkContainer to={`/units/${row.id}`}>
              <Button size="sm" variant="outline-primary" title="Editar">
                <FontAwesomeIcon icon={faEdit} fixedWidth size="xs" />
              </Button>
            </LinkContainer>
            <Button
              size="sm"
              variant="outline-primary"
              title="Eliminar"
              onClick={() => showModal(row)}>
              <FontAwesomeIcon icon={faTimes} fixedWidth size="xs" />
            </Button>
          </ButtonGroup>
        </ButtonToolbar>
      ),
    },
  ];

  /* EXPORT DATA TO EXCEL */
  const exportColumns = [
    {
      fieldName: 'id',
      title: 'ID',
    },
    {
      fieldName: 'name',
      title: 'Nombre',
    },
    {
      fieldName: 'erpCode',
      title: 'Código interno',
    },
    {
      fieldName: 'enabled',
      title: 'Activo',
      parseBoolean: true,
    },
  ];

  return (
    <div>
      {renderDeleteModal()}
      <h1 className="page-title">Unidades</h1>
      <DataTable
        columns={columns}
        data={units}
        isDataLoading={isDataLoading}
        keyField="id"
        addButton="/units/new"
        exportConfig={{
          exportURL: '/units/export.xlsx',
          columns: exportColumns,
        }}
      />
    </div>
  );
}

export default withToastManager(UnitList);
